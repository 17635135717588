import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ModalData {
	content: string;
	contentAlignment?: string;
	primaryLabel: string;
	primaryFunction?: (...args: unknown[]) => void;
	secondaryLabel: string;
	secondaryFunction?: (...args: unknown[]) => void;
	title: string;
}

type ClosedBy = 'close' | 'primary' | 'secondary';

@Component({
	selector: 'cp-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	standalone: false
})
export class ModalComponent {
	constructor(
		public dialogRef: MatDialogRef<ModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ModalData
	) { }

	closeDialog(data?: ClosedBy): void {
		// Calls associated function if one exists
		if (data === 'primary' && this.data.primaryFunction) {
			this.data.primaryFunction();
		} else if (data === 'secondary' && this.data.secondaryFunction) {
			this.data.secondaryFunction();
		}
		this.dialogRef.close(data);
	}
}